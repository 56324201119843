import { combineReducers } from "redux";
import NotificationAlert from "./NotificationState";
import UpdateTheme from "./ThemeState";
import FilteredRoutes from './FilteredRoutesState'

export default combineReducers({
  NotificationAlert,
  UpdateTheme,
  FilteredRoutes
});
