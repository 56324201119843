import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import useCustomStyles from "../hooks/CustomStylesHook";
import WebsiteAnimation from "../../src/assets/animations/LoadingCube.json";
import Lottie from "lottie-react";

const styles = (theme: any) => ({
  preLoader: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
});

const PreLoader = () => {
  const theme = useTheme();

  const classes = useCustomStyles(styles, theme);
  return (
    // @ts-ignore
    <Box className={classes?.preLoader}>
      <Box>
        <Lottie animationData={WebsiteAnimation} loop={true} />
      </Box>
    </Box>
  );
};

export default PreLoader;
