import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { NavListItems } from "./NavListItems";
import LightLogo from "../assets/icons/techsophy_logo .png";
import DarkLogo from "../assets/icons/Logo.svg";
import { Tab, Tabs, Tooltip, useTheme } from "@mui/material";
import useCustomStyles from "../hooks/CustomStylesHook";
import { Link, useLocation, useNavigate } from "react-router-dom";

const drawerWidth: number = 280;

// Styled component for customizing the Drawer component from Material-UI

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

interface NavProps {
  open: boolean;
  toggleDrawer: () => void;
  validRoutes: any[];
}

const styles = (theme: any) => ({
  headerToolbarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: theme.spacing(1) * 8,
    paddingRight: theme.spacing(1) * 8,
    minHeight: 48,
  },
  selectEffect: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    // Padding: "12px 16px",
    // background: "radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #DFEAFF 100%)",
  },
  unSeleEffect: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  sideBarTab: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
});

const selectedIcon = {
  fill: "green",
};

const unSelectedIcon = {
  fill: "grey",
};

const SideNav = ({ open, toggleDrawer, validRoutes }: NavProps) => {
  const theme = useTheme();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  let windowLocation = window.location.pathname;
  const [activeTab, setActiveTab] = React.useState(0);
  const classes = useCustomStyles(styles, theme);

  const handleTabIndexChange = async () => {
    const filtered: any = validRoutes.filter((item) => {
      return item.path === windowLocation;
    });
    if (filtered[0]) {
      await setActiveTab(filtered[0].index);
    } else {
      setActiveTab(0);
      navigate("/home");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    handleTabIndexChange();
  }, [windowLocation]);

  return (
    /* 
  Drawer component toggles visibility based on the 'open' state and contains a toolbar and list of navigation items.
*/
    <Drawer variant="permanent" open={open}>
      <Toolbar className={classes?.headerToolbarContainer}>
        <img
          src={theme.palette.mode === "dark" ? DarkLogo : LightLogo}
          alt="comapny-logo"
          width={140}
        />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      {/* <List component="nav"> */}
      {/* {NavListItems(validRoutes)} */}
      {/* <Divider sx={{ my: 1 }} />
    {secondaryListItems} */}
      {/* </List> */}

      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        orientation="vertical"
        // variant="scrollable"
        // className={classes.sideBarTabs}
      >
        {validRoutes.map((tab: any, index) => (
          <Tooltip title={tab.title} placement="right" arrow key={tab.index}>
            <Tab
              icon={
                activeTab === index ? (
                  <div className={classes?.selectEffect}>
                    <tab.icon {...selectedIcon} />
                    {open && <>{tab?.title}</>}
                  </div>
                ) : (
                  <div className={classes?.unSeleEffect}>
                    <tab.icon {...unSelectedIcon} />
                    {open && <>{tab?.title}</>}
                  </div>
                )
              }
              className={classes?.sideBarTab}
              {...a11yProps(tab.index)}
              component={Link}
              to={tab.path}
              // disabled={handleDisableTabs(tab.key)}
            ></Tab>
          </Tooltip>
        ))}
      </Tabs>
    </Drawer>
  );
};

export default SideNav;

/* Control Flow: index -> App -> Wrapper -> Header -> SideNav */
