export const ROUTES_FILTERED = "ROUTES_FILTERED";

export interface FilteredRoutesState {
  filteredRoutes: any[];
}

export interface RoutesFilteredEvent {
  type: typeof ROUTES_FILTERED;
  payload: any[];
}

export const initialFilteredRoutesState: FilteredRoutesState = {
  filteredRoutes: [],
};

export default (
  state: FilteredRoutesState = initialFilteredRoutesState,
  action: RoutesFilteredEvent
): FilteredRoutesState => {
  switch (action.type) {
    case ROUTES_FILTERED:
      return {
        ...state,
        filteredRoutes: action.payload,
      };
    default:
      return state;
  }
};