import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes as appRoutes } from "./Routes";
import { useSelector } from "react-redux";

const Navigation = () => {
  const navigate = useNavigate();

  // const { pathname } = useLocation();

  // const windowLocation = window.location.pathname;

  // const filteredRoutes = useSelector(
  //   (state: any) => state.FilteredRoutes.filteredRoutes
  // );

  // React.useEffect(() => {
  //   navigate(`${process.env.PUBLIC_URL}/home`);
  // }, []);

  return (
    <>
      <Routes>
        {appRoutes.map((route: any) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          />
          // Add additional routes in Routes.ts as required.
        ))}
      </Routes>
    </>
  );
};

export default Navigation;

/* Control Flow: index -> App -> Navigator*/
