import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";

export default function MUITable({
  rows,
  columns,
  tableHeight,
  loading,
  ...rest
}: {
  rows: any;
  columns: any;
  tableHeight?: any;
  loading?: boolean;
  [key: string]: any;
}) {
  return (
    <Box sx={{ height: tableHeight, width: "100%" }}>
      <DataGrid
        isCellEditable={(params: GridCellParams) => false}
        rows={rows}
        columns={columns}
        {...rest}
        density="compact"
        hideFooter={true}
        loading={loading ?? false}
      />
    </Box>
  );
}
