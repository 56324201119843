export const ETL = [
  { label: "SAS", value: "sas" },
  { label: "COBOL ", value: "cobol" },
];
export const SUCCESS_MESSAGE = "success";
export const ASSET_NAME_UPDATE_SUCCESS = "Asset name updated successfully!";
export const ASSET_NAME_UPDATE_ERROR = "Error updating asset name";
export const SAS = "sas";
export const COBOL = "cobol";
export const PROJECT_NAME_REQUIRED = "Project Name is required";
export const FILE_TYPES_REQUIRED = "File Types are required";
export const DESCRIPTION_REQUIRED = "Description are required";
export const DEVELOPER_REQUIRED = "User are required";
