import { request, ResponseProps } from "./Request";

export const getMenu = async () => {
    const res: ResponseProps = (await request.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}/accounts/v1/roles/menu`
    )) as ResponseProps;
    if (res.success) {
      const data = res.data;
      return data;
    }
    return {};
  };