import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useAppDispatch, useAppSelector } from "../redux/Hook";
import Header from "./Header";
import { StyledEngineProvider } from "@mui/material/styles";
import Notification from "../components/Notification";
import { initialAlertState } from "../redux/reducers/NotificationState";
import LoadSxpChat from "../components/chatWidget";
import useCustomStyles from "../hooks/CustomStylesHook";
import { useTheme } from "@emotion/react";
import { getMenu } from "../services/MenuServices";
import { routes as appRoutes } from "../Routes";
import PreLoader from "../components/PreLoader";
// We use the Material-UI (MUI) library for styling

const styles = (theme: any) => ({
  wrapperContainer: {
    display: "flex",
  },
  contentBoxContainer: {
    padding: "0 1% 0 1%",
  },
  customBackground: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
});

const Wrapper = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const dispatch = useAppDispatch();
  const themeDataState = useAppSelector((state) => state.UpdateTheme);
  const notifyDataState = useAppSelector((state) => state.NotificationAlert);

  const [validRoutes, setValidRoutes] = React.useState([]);

  const handleGetMenus = async () => {
    try {
      const response: any = await getMenu();
      if (response && response?.length > 0) {
        const routeKeys = response?.map((menu: any) => menu.url);
        const uniqueTitles = new Set();
        const filteredRoutes: any = appRoutes
          .filter((route) => routeKeys.includes(route.key))
          .filter(
            (route) =>
              !uniqueTitles.has(route.title) && uniqueTitles.add(route.title)
          )
          .map((route, index) => ({ ...route, index }));

        setValidRoutes(filteredRoutes);
        dispatch({
          type: "ROUTES_FILTERED",
          payload: filteredRoutes,
        });
      } else {
        setValidRoutes([]);
        dispatch({
          type: "ROUTES_FILTERED",
          payload: [],
        });
      }
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  };

  // Function to reset notification data
  const resetNotificationData = () => {
    dispatch({
      type: "SEND_ALERT",
      data: {
        enable: initialAlertState.enable,
        type: initialAlertState.type,
        message: initialAlertState.message,
        duration: initialAlertState.duration,
      },
    });
  };

  // Application Theme

  const theme = createTheme({
    palette: {
      mode: themeDataState?.mode,
      primary: {
        main: themeDataState?.primary,
        light: themeDataState?.primaryLight,
        contrastText: "#f8f9fa",
      },
      secondary: {
        main: themeDataState?.secondary,
        contrastText: "#f8f9fa",
      },
      error: {
        main: themeDataState?.error,
      },
    },
    typography: {
      fontFamily: "Helvetica, Arial, sans-serif",
    },
  });

  // Apllication Theme End
  const classes = useCustomStyles(styles, theme);

  React.useEffect(() => {
    handleGetMenus();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Notification
          open={notifyDataState.enable}
          type={notifyDataState.type}
          message={notifyDataState.message}
          duration={notifyDataState.duration}
          setOpen={() => resetNotificationData()}
        />
        {validRoutes.length > 0 ? (
          <Box className={classes?.wrapperContainer}>
            <CssBaseline />
            {/* Header Component provides consistent navigation or information across the application. */}
            <Header validRoutes={validRoutes} />

            <Box
              component="main"
              className={classes?.customBackground}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
              }}
            >
              <Toolbar />
              <Box className={classes?.contentBoxContainer}>
                {props.children}
                {/* <Copyright sx={{ pt: 4 }} /> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <PreLoader />
        )}
        {/* <LoadSxpChat /> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Wrapper;

/* Control Flow: index -> App -> Wrapper */
